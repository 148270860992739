import React from 'react';

import styled from 'styled-components';

import {
  LandingBlock,
  BlockHeader,
} from './styled';

import cover from '../assets/book_dark.jpeg'


const PhilosophyWrapper = styled.div`
  background: url(${cover}) #000;
  background-repeat: no-repeat;
  background-position: center -18vw;
  background-size: 100% auto;
  padding: 23.8vw 0 12.5rem;

  @media (max-width: 680px), screen and (orientation: portrait) {
    background-size: 200% auto;
    background-position: center -47.6vw;
  }
`;

let i = 0;

const Philosophy = ({ setParams }) => (
  <PhilosophyWrapper
    ref={(node) => {
      if (node && i < 3) {
        i++;
        setParams({
          top: node.offsetTop,
          height: node.offsetHeight,
        });
      }
    }}
  >
    <LandingBlock dark>
      <BlockHeader dark>
        Philosophy
      </BlockHeader>
      <p>
        We believe that lean development and highly modular architecture helps to build a successful product. Our approach is to split projects into base pieces developed by small dev teams in short sprints. The foundation of this idea described in <a href="https://agilemanifesto.org/principles.html">agile manifesto</a>. We don't treat Agile or Scrum as the silver-bullet framework. Only rational usage of these methods leads to success. That’s our firm belief.
      </p>
    </LandingBlock>
  </PhilosophyWrapper>
);

export default Philosophy;

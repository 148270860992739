import styled, {
  css,
} from 'styled-components';

export const LandingBlock = styled.div`
  margin: 0 auto;
  max-width: 950px;
  box-sizing: border-box;
  padding: 6.25rem 3.125rem;
  color: ${({ dark }) => (dark ? '#fff' : '#000')};
  font-size: 1.25rem;
  a, a:hover, a:visited {
    color: ${({ dark }) => (dark ? '#add8e6' : '#003366')};
  }

  @media (max-width: 680px), screen and (orientation: portrait) {
    padding: 2em 1em;
  }
`;

export const WideBlock = styled(LandingBlock)`
  max-width: 1200px;

  @media (max-width: 680px), screen and (orientation: portrait) {
    padding: 5em 0em;
  }
`;

export const BlockHeader = styled.h2`
  font-size: 2.625rem;
  color: ${({ dark }) => (dark ? '#fff' : '#000')};

    @media (max-width: 680px), screen and (orientation: portrait) {
      text-align: center;
    }
`;

const greenCallToAction = css`
  a& {
    color: #007733;
    :hover {
      color: #009933;
    }
  }
`;

export const CallToAction = styled.a.attrs(({ href }) => ({
  href: href || 'mailto:hi@lambdabird.com',
  rel: 'noopener noreferrer',
  target: '_blank',
}))`
  display: block;
  text-align: center;
  font-size: 1.2em;
  ${({ green }) => green && greenCallToAction}
`;

import React from 'react';

import {
  cardType,
} from './types';

import {
  TileWrapper,
  TileTitle,
  TileContent,
} from './styled';

const Tile = ({
  container,
  title,
  content,
}) => (
  <TileWrapper
    {...container}
  >
    {title && (
      <TileTitle>
        {title}
      </TileTitle>
    )}
    <TileContent>
      {content}
    </TileContent>
  </TileWrapper>
);

Tile.propTypes = cardType;
Tile.defaultProps = {
  container: {},
  title: '',
};

export default Tile;

/* global window */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';

import styled, {
  css,
} from 'styled-components';

// cubic-bezier(1,.01,1,.19);
const largeTransition = css`
  transition-property: margin, font-size, letter-spacing, top, left, background;
  transition-duration: 500ms;
`;

const HeaderBlock = styled.div`
  width: 100%;
  z-index: 100;
  position: fixed;
  transition: top ${({ minified }) => (minified ? '500ms' : '0ms')};
  ${({ minified }) => minified && largeTransition}
`;

const HeaderPlaceholder = styled.div`
  height: 25rem;
  @media (max-width: 680px), screen and (orientation: portrait) {
    height: 15rem;
  }
`;

const SymbolLogo = styled.h1`
  text-align: center;
  font-size: ${({ large }) => (large ? '16rem' : '4rem')};
  color: #000;
  font-weight: 900;
  letter-spacing: ${({ large }) => (large ? '-2.625rem' : '-0.625rem')};
  margin-left: ${({ large }) => (large ? '-2.625rem' : '-0.625rem')};
  padding: 1.25rem 0 1.75rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  width: auto;
  margin: 0 auto;

  color: ${({ dark }) => (!dark ? '#000' : '#fff')};
  background: ${({ dark }) => (dark ? '#000' : '#fff')};

  ${largeTransition}

  @media (max-width: 680px), screen and (orientation: portrait) {
    font-size: ${({ large }) => (large ? '12rem' : '4rem')};
    letter-spacing: ${({ large }) => (large ? '-1.96875rem' : '-0.625rem')};
    margin-left: ${({ large }) => (large ? '-1.96875rem' : '-0.625rem')};
  }
`;

const Name = styled.span`
  font-size: ${({ large }) => (large ? '4rem' : '1.5rem')} !important;
  display: block;
  position: absolute;
  letter-spacing: normal;

  @media (max-width: 680px), screen and (orientation: portrait) {
    font-size: ${({ large }) => (large ? '2.5rem' : '1.5rem')} !important;
  }

  ${largeTransition}
`;

const Logo = styled.span`
  margin-left: ${({ large }) => (large ? '-1.25rem' : '-3.75rem')};

  @media (max-width: 680px), screen and (orientation: portrait) {
    margin-left: ${({ large }) => (large ? '1rem' : '-3.75rem')};
  }
`;

const Lambda = styled(Name)`
  left: calc(50% ${({ large }) => (large ? '- 12.5' : '+ 0.75')}rem);
  top: ${({ large }) => (large ? '18.75' : '2.3125')}rem;

  @media (max-width: 680px), screen and (orientation: portrait) {
    left: calc(50% ${({ large }) => (large ? '- 8' : '+ 0.75')}rem);
    top: ${({ large }) => (large ? '14.0625' : '2.3125')}rem;
  }
`;

const Bird = styled(Name)`
  left: calc(50% ${({ large }) => (large ? '+ 3.75' : '+ 0.75')}rem);
  top: ${({ large }) => (large ? '18.75' : '3.875')}rem;
  @media (max-width: 680px), screen and (orientation: portrait) {
    left: calc(50% ${({ large }) => (large ? '+ 2.25' : '+ 0.75')}rem);
    top: ${({ large }) => (large ? '14.0625' : '3.875')}rem;
  }
`;

const DEFAULT_THRESHOLD = 150;

const isInDark = (darkBoudaries, plus = 0) => scroll => darkBoudaries.some(
    ([startBlock, endBlock]) => (
      ((scroll + plus) > startBlock)
      && ((scroll + plus) < endBlock)
    ),
  );

const getInitialState = (scrollY, darkBoudaries) => (isDefaultScroll => ({
  default: isDefaultScroll,
  minified: !isDefaultScroll,
  dark: isInDark(darkBoudaries)(scrollY),
}))(scrollY < DEFAULT_THRESHOLD);

const Header = ({ darkBoudaries }) => {
  const [state, setState] = useState(getInitialState(window.scrollY || 0, darkBoudaries));
  const lastScroll = useRef(window.scrollY || 0);
  const headerBlockRef = useRef(null);

  const onScroll = useCallback(() => {
    lastScroll.current = window.scrollY;
    if (window.scrollY < DEFAULT_THRESHOLD) {
      setState({
        default: true,
        minified: false,
        dark: false,
      });
      return;
    }
    const dark = isInDark(darkBoudaries, (headerBlockRef.current && headerBlockRef.current.offsetHeight))(window.scrollY);
    if (
      !state.minified
       || (dark && !state.dark)
       || (!dark && state.dark)
    ) {
      setState({
        default: false,
        minified: true,
        dark,
      });
    }
  }, [state, setState, darkBoudaries]);

  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [darkBoudaries]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <HeaderBlock
        {...state}
        ref={headerBlockRef}
        style={{
          top: state.default ? `-${lastScroll.current}px` : '0px',
        }}
      >
        <SymbolLogo
          large={state.default}
          dark={state.dark}
        >
          <Logo large={state.default}>
            ΛV
          </Logo>
          <Lambda large={state.default}>Lambda</Lambda>
          <Bird large={state.default}>Bird</Bird>
        </SymbolLogo>
      </HeaderBlock>
      <HeaderPlaceholder />
    </>
  );
};

export default Header;

import React from 'react';
import styled from 'styled-components';

import {
  LandingBlock,
} from './styled';

const MottoWrapper = styled(LandingBlock)`
  padding: 100px 50px 300px;
`;

const MottoText = styled.h2`
  font-size: 2rem;
  padding: 2em 0;
  margin: 0;
  font-weight: normal;
  @media (max-width: 680px), screen and (orientation: portrait) {
    font-size: 1.5rem;
  }
`;

const Motto = () => (
  <MottoWrapper>
    <MottoText>
      IT consulting and custom solutions for web, desktop, mobile.
      Our focus is on the best UX, cutting-edge technologies and making your product a success.
    </MottoText>
  </MottoWrapper>
);

export default Motto;

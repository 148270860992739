import React from 'react';

import {
  WideBlock,
  BlockHeader,
  CallToAction,
} from './styled';

import Cards from './Cards';


const Careers = () => (
  <>
    <WideBlock>
      <BlockHeader>
        Careers
      </BlockHeader>
      <Cards
        cards={[
          {
            id: 'pythonSr',
            container: {},
            title: 'Senior full-stack engineer',
            content: (
              <>
                <ul>
                  <li>4+ years experience as back-end developer</li>
                  <li>Python, Node.js</li>
                  <li>Extensive knowledge of SQL/NoSQL DB's</li>
                  <li>AWS, Docker/Kubernetes experience</li>
                  <li>Degree in Computer Science / Applied Math / Math / or other engineering field</li>
                  <li>Experience with any frontend framework will be a plus</li>
                  <li>Machine learning interest/experience will be a plus</li>
                </ul>
                <CallToAction
                  href="mailto:hiring@lambdabird.com"
                >
                  Apply
                </CallToAction>
              </>
            )
          },
          {
            id: 'reactMd',
            container: {},
            title: 'Middle React developer',
            content: (
              <>
                <ul>
                  <li>2+ years of React front-end development</li>
                  <li>Strong knowledge of CSS, JS, Node.js</li>
                  <li>Docker, Kubernetes will be a plus</li>
                  <li>English — at least upper-intermediate</li>
                  <li>German will be a plus</li>
                </ul>
                <CallToAction
                  href="mailto:hiring@lambdabird.com"
                >
                  Apply
                </CallToAction>
              </>
            )
          },
        ]}
      />
    </WideBlock>
  </>
);

export default Careers;

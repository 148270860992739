import React from 'react';
import Particles from 'react-particles-js';
import styled from 'styled-components';

import {
  WideBlock,
  BlockHeader,
  CallToAction,
} from './styled';

import Cards from './Cards';

const ProjectsWrapper = styled.div`
  position: relative;
`;

const ParticlesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

const Remark = styled.span`
  font-size: .75em;
  position: relative;
  top: -.5em;
  padding-left: .5em;
  color: #444;
  @media (max-width: 680px), screen and (orientation: portrait) {
    font-size: .6em;
  }
`;

const ProjectLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  text-decoration: none;
  :before {
    content: '>';
    padding-right: .25rem;
    font-weight: 900;
  }
`;

const Projects = () => (
  <ProjectsWrapper>
    <ParticlesWrapper>
      <Particles
        height="100vh"
        params={{
          particles: {
    	      number: {
    	        value: 50,
              density: {
                enable: true,
                value_area: 500,
              }
    	      },
    	      size: {
    	        value: 3,
    	      },
            color: {
              value: '#000',
            },
            line_linked: {
  	          enable: true,
              color: '#000'
  	        }
    	    },
        }}
      />
    </ParticlesWrapper>
    <WideBlock>
      <BlockHeader>
        Our Projects
      </BlockHeader>
      <Cards
        cards={[
          // {
          //   id: 'myn',
          //   container: {},
          //   title: 'Myntelligence',
          //   content: (
          //     <>
          //       <ProjectLink
          //         href="https://myntelligence.com/"
          //       >
          //         Check out
          //       </ProjectLink>
          //       <p>
          //         The omnichannel advertising platform for the enterprise.
          //         We've been a part of UI team working on dashboards, 3rd party 	platforms integrations and complex charts for data analysis
          //       </p>
          //     </>
          //   )
          // },
          {
            id: 'localRelayer',
            container: {},
            title: 'Local Relayer',
            content: (
              <>
                <ProjectLink
                  href="https://localrelayer.com/"
                >
                  Check out
                </ProjectLink>
                <p>
                  Open-source decentralized exchange on top of the 0x protocol. Founded 2 years ago, received the grant from the 0x foundation.
                </p>
              </>
            )
          },
          {
            id: 'memberry',
            container: {},
            title: (
              <>
                Memberry
                <Remark>
                  QA & α-release
                </Remark>
              </>
            ),
            content: (
              <>
                <p>
                  Android and ios application for learning languages utilizing spaced repetition (SM2+ algorithm).
                </p>
                <CallToAction
                  href="mailto:admin@lambdabird.com"
                >
                  Apply to beta testing
                </CallToAction>
              </>
            )
          },
          {
            id: 'agileMeetings',
            container: {},
            title: (
              <>
                AgileMeetings
                <Remark>
                  R & D
                </Remark>
              </>
            ),
            content: (
              <>
                <p>
                  Software product for Agile teams who desire better meetings preparation, keynoting and decision making processes.
                </p>
                <CallToAction
                  href="mailto:admin@lambdabird.com"
                >
                  Get to know more
                </CallToAction>
              </>
            )
          },
        ]}
      />
    </WideBlock>
  </ProjectsWrapper>
);

export default Projects;

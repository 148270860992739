import React from 'react';
import styled from 'styled-components';

import {
  WideBlock,
  BlockHeader,
  CallToAction,
} from './styled';

import Cards from './Cards';

const TeamsWrapper = styled.div`
  padding: 6.25rem 0;
`;

const Teams = () => (
  <TeamsWrapper>
    <WideBlock>
      <BlockHeader>
        Teams
      </BlockHeader>
      <Cards
        cards={[
          {
            id: 'ecommerceTeam',
            container: {},
            title: 'Ecommerce',
            content: (
              <>
                <p>
                  The team can help you with a custom solution for your retail business or assemble the right tool for your firm on top of the Shopify platform. Our e-commerce team was formed and framed during the development of a huge retail platform for cannabis distribution. The project included custom CRM and inventory systems, web marketplace and mobile app for delivering personal.
                </p>
                <p>
                  The rates of the team members start from <b>$25/hour</b>.
                </p>
                <CallToAction>
                  Discuss cooperation
                </CallToAction>
              </>
            )
          },
          {
            id: 'fintechTeam',
            container: {},
            title: 'Fintech & Blockchain',
            content: (
              <>
                <p>
                  The team utilizes Ethereum, EOS and Bitcoin blockchains, identity solutions, and smart contracts to build secure decentralized systems. Fintech & Blockchain team have experience with exchanges, trading bots, and mobile banking. Check out our just released open-sourced decentralized exchange <a href="https://localrelayer.com/">Local Relayer</a>.
                </p>
                <p>
                  The rates of the team members starts from <b>$30/hour</b>.
                </p>
                <CallToAction
                  green
                  href="mailto:tim@lambdabird.com"
                >
                  Hire now
                </CallToAction>
              </>
            )
          },
          {
            id: 'xFlowTeam',
            container: {},
            title: 'SaaS',
            content: (
              <>
                <p>
                The team will help you to research the market, select tech stack and build MVP to validate your hypotheses. Highly motivated people that want to be part of the next big thing.
                </p>
                <p>
                  The rates of the team members starts from <b>$40/hour</b>, equity conditions may be discussed.
                </p>
                <CallToAction>
                  Discuss cooperation
                </CallToAction>
              </>
            )
          },
        ]}
      />
    </WideBlock>
  </TeamsWrapper>
);

export default Teams;

import styled from 'styled-components';

export const CardsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
`;

export const TileTitle = styled.h3`
  display: block;
  height: 2em;
  font-size: 1.17em;
  margin-bottom: 0;
  @media (max-width: 680px), screen and (orientation: portrait) {
    font-size: 1em;
  }
`;

export const TileWrapper = styled.div`
  max-width: 400px;
  padding: 1em 2em;
  background: ${({ background }) => background || '#f5f5f5'};
  margin: 1.25rem;
  border-radius: 3px;

    @media (max-width: 680px), screen and (orientation: portrait) {
      padding: 0.2em 1em;
      margin: 0.5rem;
    }
`;

export const TileContent = styled.div`
  font-size: 1rem;
  ul {
    padding-left: 1em;
    list-style: none;
    li {
      padding-left: 1em;
      text-indent: -1em;
      padding-bottom: 0.5em;
    }

    li:before {
      content: '>';
      padding-right: .25rem;
      font-weight: 900;
    }
  }
`;

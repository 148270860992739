import React, {
  useState,
} from 'react';

import {
  createGlobalStyle,
} from 'styled-components';

import Header from './components/Header';
import Motto from './components/Motto';
import Philosophy from './components/Philosophy';
import Teams from './components/Teams';
import Careers from './components/Careers';
import Projects from './components/Projects';
import ContactUs from './components/ContactUs';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: "Roboto Mono", monospace;
    margin: 0;
    padding: 0;
  }
`;

const App = () => {
  const [philosophyBlockParams, setPhilosophyBlockParams] = useState({
    top: 730,
    height: 1100,
  });
  return (
    <>
      <GlobalStyles />
      <Header
        darkBoudaries={[
          [
            philosophyBlockParams.top,
            philosophyBlockParams.top + philosophyBlockParams.height,
          ],
        ]}
      />
      <Motto />
      <Philosophy
        setParams={setPhilosophyBlockParams}
      />
      <Teams />
      <Projects />
      <Careers />
      <ContactUs />
    </>
  )
};

export default App;

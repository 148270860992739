import React from 'react';
import PropTypes from 'prop-types';

import {
  cardType,
} from './types';

import Tile from './Tile';
import {
  CardsList,
} from './styled';


const Cards = ({
  cards,
}) => (
  <CardsList>
    {cards.map(card => (
      <Tile
        key={card.id}
        {...card}
      />
    ))}
  </CardsList>
);

Cards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(cardType)).isRequired,
};

export default Cards;

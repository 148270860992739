import PropTypes from 'prop-types';

export const cardType = {
  id: PropTypes.string.isRequired,
  container: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  content: PropTypes.node.isRequired,
};

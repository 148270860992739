import React from 'react';
import styled from 'styled-components';

import {
  BlockHeader,
  LandingBlock,
} from './styled';

const MailLink = styled.a`
  display: block;
  margin: 5em auto;
  font-size: 2rem;
  text-align: center;
  @media (max-width: 680px), screen and (orientation: portrait) {
    font-size: 1.5rem;
    margin: 1em auto;
  }
`;

const LinkWrapper = styled.div`
  @media (max-width: 680px), screen and (orientation: portrait) {
    height: 100%;
    min-height: calc(100vh - 20rem);
  }
`;

const ContactUs = () => (
  <LandingBlock>
    <BlockHeader>
      Contact Us
    </BlockHeader>
    <LinkWrapper>
      <MailLink href="mailto:hi@lambdabird.com">hi@lambdabird.com</MailLink>
    </LinkWrapper>
  </LandingBlock>
);

export default ContactUs;
